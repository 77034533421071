import { GetOperationsQuery } from '../../../../__generated__/graphql';
import { FlattenFunction } from '../../../../types';
import {
  calculateDuration,
  formatTimestamp,
  generateOperationNumber,
  generateOperationResultLabel,
  mapOperationalLifeCycleDisplayLabel
} from '../../../../utilities';
import { DataGridOperationResultCellProps } from '../../../4-features';

export type OperationRow = FlattenFunction<typeof generateOperationRows>;

export const generateOperationRows = (data?: GetOperationsQuery, cookieTimezone?: string) => {
  if (!data) {
    return [];
  }

  return data.deviceOperations.map((deviceOperation) => ({
    id: deviceOperation.id,
    serialNumber: generateOperationNumber(deviceOperation.device.serialNumber || '', deviceOperation.sequence || 0),
    result: {
      deviceResultId: deviceOperation.deviceOperationResult?.id,
      deviceAnnotatedResultId: deviceOperation.deviceOperationAnnotatedResult?.id,
      success: deviceOperation.deviceOperationFinalResult?.isSuccessful,
      hasEndedAt: Boolean(deviceOperation.endAt),
      hasOperationResult: Boolean(deviceOperation.deviceOperationResult),
      label: generateOperationResultLabel(deviceOperation),
      title: generateOperationResultLabel(deviceOperation)
    } as DataGridOperationResultCellProps,
    dateTime: formatTimestamp(deviceOperation.startAt, cookieTimezone),
    duration: calculateDuration(deviceOperation.startAt, deviceOperation.endAt),
    operationalLifeCycle: mapOperationalLifeCycleDisplayLabel(deviceOperation.operationalLifeCycle),
    customer: deviceOperation.device.site.customer.company.name,
    site: deviceOperation.device.site.name,
    program: deviceOperation.device.program?.name,
    customerId: deviceOperation.device.site.customer.company.id,
    deviceId: deviceOperation.device.id
  }));
};
