import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';

import { CustomerCountBlocks } from './customer-count-blocks';
import { CustomerProgramsTable } from './customer-programs-table';
import { GetCustomerDetailsQuery } from '../../../../__generated__/graphql';
import ArrowLeftIcon from '../../../../assets/icons/arrow-left.svg?react';
import { TIMEZONE_COOKIE_NAME } from '../../../../constants';
import { Flatten } from '../../../../types';
import { formatTimestamp } from '../../../../utilities';
import { InformationBlock, InternalLink } from '../../../5-elements';

interface CustomerDetailsPageProps {
  title: string;
  counts: {
    devices: number;
    sites: number;
    programs: number;
  };
  createdAt: string;
  updatedAt: string;
  programs: Flatten<GetCustomerDetailsQuery['customers']>['programs'];
}

export const CustomerDetailsSidePanel = ({
  title,
  counts,
  createdAt,
  updatedAt,
  programs
}: CustomerDetailsPageProps): JSX.Element => {
  const { t } = useTranslation();
  const [cookies] = useCookies([TIMEZONE_COOKIE_NAME]);

  return (
    <section className="customer-details-side-panel" data-testid="customer-details-side-panel">
      <div className="customer-details-side-panel__title">
        <InternalLink
          to="/portal/customers"
          icon={<ArrowLeftIcon />}
          text={t('customerDetailsPage.sidePanel.backLinkText')}
        />
        <h1>{title}</h1>
      </div>
      <div className="customer-details-side-panel__customer-information">
        <div className="customer-details-side-panel__customer-statistics">
          <CustomerCountBlocks devices={counts.devices} sites={counts.sites} programs={counts.programs} />
          <div className="customer-details-side-panel__dates" data-testid="customer-details-side-panel-dates">
            <InformationBlock
              label={t('customerDetailsPage.sidePanel.dates.createdAt')}
              subValue={formatTimestamp(createdAt, cookies[TIMEZONE_COOKIE_NAME])}
            />
            <InformationBlock
              label={t('customerDetailsPage.sidePanel.dates.updatedAt')}
              subValue={formatTimestamp(updatedAt, cookies[TIMEZONE_COOKIE_NAME])}
            />
          </div>
        </div>
        <CustomerProgramsTable programs={programs} />
      </div>
    </section>
  );
};
