import { useMutation, useQuery } from '@apollo/client';
import axios, { AxiosError } from 'axios';
import { filesize } from 'filesize';
import { get, head, isEmpty } from 'lodash';
import { useSnackbar } from 'notistack';
import { Dispatch, JSX, SetStateAction, useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';

import { OperationLogFetchSkipped } from './operation-log-fetch-skipped';
import { graphqlApiConfig } from '../../../../configs';
import { TIMEZONE_COOKIE_NAME } from '../../../../constants';
import { MUTATION_REMOTE_OPERATION_LOG } from '../../../../services/mutations';
import {
  QUERY_GET_DEVICE_ONLINE_STATUS,
  QUERY_GET_OPERATION_LOG,
  QUERY_GET_OPERATION_LOG_URLS
} from '../../../../services/queries';
import { ObjectUploadStatus, OperationLogType, SnackbarMessageType } from '../../../../types';
import { constructSnackbarMessage, contextualizeBoolean, formatTimestamp } from '../../../../utilities';
import {
  BlobModalBase,
  BlobModalBaseProps,
  CodeViewer,
  ModalDrawerFooter,
  ModalDrawerHeader,
  UploadStatusChip
} from '../../../4-features';
import { InformationBlock, Loading, RSButton } from '../../../5-elements';

interface OperationLogModalProps extends Omit<BlobModalBaseProps, 'children'> {
  log: OperationLogType;
  logTitle: string;
  operationId: string;
  deviceId: string;
  setOpenOperationLog: Dispatch<SetStateAction<boolean>>;
}

export const OperationLogModal = ({
  log,
  setOpenOperationLog,
  operationId,
  logTitle,
  deviceId,
  ...props
}: OperationLogModalProps): JSX.Element => {
  const { t } = useTranslation();
  const [cookies] = useCookies([TIMEZONE_COOKIE_NAME]);
  const { enqueueSnackbar } = useSnackbar();
  const sendMessageToSnackbar = (...args: SnackbarMessageType): void => {
    enqueueSnackbar(constructSnackbarMessage(...args));
  };
  const [logContents, setLogContents] = useState<string | undefined>(undefined);
  const [s3RequestError, setS3RequestError] = useState<AxiosError | undefined>(undefined);

  /*
    The log URL will not be fetched if:
    (1) When it is not uploaded (log.status !== UploadDone), or
    (2) The modal is closed
  */
  const logUrlFetchSkipped = log.status !== ObjectUploadStatus.UploadDone || !props.open;
  const {
    loading: loadingLogUrl,
    error: errorLogUrl,
    data: dataLogUrl
  } = useQuery(QUERY_GET_OPERATION_LOG_URLS, {
    skip: logUrlFetchSkipped,
    fetchPolicy: 'no-cache',
    variables: { ids: [log.id] },
    onError: (error) => {
      sendMessageToSnackbar(
        t('operationsPage.operationDetails.logModal.urlRequestError'),
        error.name,
        error.message,
        'error'
      );
    }
  });

  /*
    The online status of the device will not be fetched if:
    (1) The log has already been uploaded to the cloud, so the online status no longer matters; or
    (2) The modal is closed
  */
  const deviceOnlineStatusFetchSkipped = log.status === ObjectUploadStatus.UploadDone || !props.open;
  const { loading: loadingDeviceOnlineStatus, data: dataDeviceOnlineStatus } = useQuery(
    QUERY_GET_DEVICE_ONLINE_STATUS,
    {
      variables: { deviceId },
      skip: deviceOnlineStatusFetchSkipped,
      fetchPolicy: 'network-only',
      onError: (error) => {
        sendMessageToSnackbar(
          t('operationsPage.operationDetails.logModal.deviceOnlineStatusRequestError'),
          error.name,
          error.message,
          'error'
        );
      }
    }
  );

  const [requestLog, { loading: loadingRequestLog, error: errorRequestLog }] = useMutation(
    MUTATION_REMOTE_OPERATION_LOG,
    {
      context: { timeout: graphqlApiConfig.mutationTimeout },
      onError: (error) => {
        sendMessageToSnackbar(
          t('operationsPage.operationDetails.logModal.remoteLogRequestError'),
          error.name,
          error.message,
          'error'
        );
      }
    }
  );

  /*
    The purpose of the query (QUERY_GET_OPERATION_LOG) below is to check the status of the log and update the cache with
    the latest state.
    It will be skipped if:
    (1) The log has already been uploaded to the cloud (UploadDone), so the online status no longer matters; or
    (2) The log can no longer be requested (log.status is either UploadFailed or UploadFailedNotFound), or
    (3) The log is in draft status, or
    (4) An error occurred when requesting the log (mutation) from the device, or
    (5) The modal is closed
  */
  const updateOperationLogSkipped =
    log.status === ObjectUploadStatus.UploadDone ||
    log.status === ObjectUploadStatus.Draft ||
    log.status === ObjectUploadStatus.UploadFailed ||
    log.status === ObjectUploadStatus.UploadFailedNotFound ||
    Boolean(errorRequestLog) ||
    !props.open;
  useQuery(QUERY_GET_OPERATION_LOG, {
    variables: { operationLogId: log.id },
    skip: updateOperationLogSkipped,
    pollInterval: 3000,
    fetchPolicy: 'network-only',
    onError: (error) => {
      sendMessageToSnackbar(
        t('operationsPage.operationDetails.logModal.updateLogDetailsError'),
        error.name,
        error.message,
        'error'
      );
    }
  });

  const handleRequestLog = (): void => {
    requestLog({ variables: { deviceOperationId: operationId } });
  };

  // If the log URL state has been updated and there is a value, we request S3 to fetch the log file.
  const logUrl = head(dataLogUrl?.requestDeviceOperationLogUrls)?.url;
  useEffect(() => {
    const getLogContents = async (url: string) => {
      try {
        // Consider useFetch() in the future for better state management
        const response = await axios.get<string>(url);
        setLogContents(response.data);
      } catch (requestError) {
        const error = requestError as AxiosError;
        setS3RequestError(error);
        sendMessageToSnackbar(
          t('operationsPage.operationDetails.logModal.s3LogDownloadError'),
          error.name,
          error.message,
          'error'
        );
      }
    };
    if (!isEmpty(logUrl)) {
      getLogContents(logUrl!);
    }
  }, [logUrl]);

  const requestingLogStatus =
    log.status === ObjectUploadStatus.UploadRequestQueued ||
    log.status === ObjectUploadStatus.UploadRequested ||
    loadingRequestLog;
  // The value which corresponds to "Online" is 2
  const isDeviceOnline = get(dataDeviceOnlineStatus, 'deviceByPK.deviceMeasurementValues[0].value') === 2;

  return (
    <BlobModalBase {...props} onClose={() => setOpenOperationLog(false)}>
      <div className="operation-log-modal" data-testid="operation-log-modal">
        <ModalDrawerHeader
          title={t('operationsPage.operationDetails.logModal.title', { title: logTitle })}
          handleClose={() => setOpenOperationLog(false)}
        />
        <div className="operation-log-modal__log">
          {/* Show the "placeholder" if the log URL is not available, or error occurred when requesting the URL */}
          {(logUrlFetchSkipped || errorLogUrl) && (
            <OperationLogFetchSkipped
              hasErrorLogUrl={Boolean(errorLogUrl)}
              isDeviceOnline={isDeviceOnline}
              logStatus={log.status}
              requestingLogStatus={requestingLogStatus}
              handleRequestLog={handleRequestLog}
            />
          )}
          {/* Show the loading component when the log URL is being requested, or the log is being downloaded from S3 */}
          {(logUrl || loadingLogUrl || loadingDeviceOnlineStatus) && !logContents && !s3RequestError && (
            <div className="operation-log-modal__log-url-loading" data-testid="operation-log-modal-log-loading">
              <Loading />
            </div>
          )}
          {/* Only show the code viewer if logContents (S3 object response) is available */}
          {logContents && <CodeViewer data={logContents} />}
        </div>
        <ModalDrawerFooter
          informationBlocks={
            <>
              <InformationBlock
                label={t('operationsPage.operationDetails.logModal.result')}
                value={<UploadStatusChip uploadStatus={log.status} />}
              />
              <InformationBlock
                label={t('operationsPage.operationDetails.logModal.size')}
                value={filesize(log.size, { base: 10, round: 1 })}
              />
              <InformationBlock
                label={t('operationsPage.operationDetails.logModal.requestTries')}
                value={log.requestTries}
              />
              <InformationBlock
                label={t('operationsPage.operationDetails.logModal.autoTriggered')}
                value={contextualizeBoolean(
                  t('operationsPage.operationDetails.logModal.autoTriggeredTrue'),
                  t('operationsPage.operationDetails.logModal.autoTriggeredFalse'),
                  log.autoTriggered
                )}
              />
              <InformationBlock
                label={t('operationsPage.operationDetails.logModal.createdAt')}
                value={formatTimestamp(log.createdAt, cookies[TIMEZONE_COOKIE_NAME])}
              />
            </>
          }
          actions={
            <>
              {logUrl ? (
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={logUrl}
                  data-testid="operation-log-modal-download-link"
                >
                  <RSButton variant="contained" data-testid="operation-log-modal-download-button">
                    {t('operationsPage.operationDetails.logModal.downloadLog')}
                  </RSButton>
                </a>
              ) : (
                <RSButton variant="contained" disabled={true} data-testid="operation-log-modal-download-button">
                  {t('operationsPage.operationDetails.logModal.downloadLog')}
                </RSButton>
              )}
            </>
          }
        />
      </div>
    </BlobModalBase>
  );
};
