import { zodResolver } from '@hookform/resolvers/zod';
import { every, filter, includes, isEqual, omit, pick, uniq } from 'lodash';
import { DateTime } from 'luxon';
import queryString from 'query-string';
import { JSX, useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useLocation, useSearchParams } from 'react-router-dom';

import { getOperationResultDisplayOption } from './operation-result-display-option';
import { OperationsFilterDatePicker } from './operations-filter-date-picker';
import {
  GetCustomerNamesQuery,
  GetOperationResultsQuery,
  GetProgramNamesQuery,
  GetSerialNumbersQuery,
  GetSiteNamesQuery,
  OperationalLifeCycle
} from '../../../../__generated__/graphql';
import { TIMEZONE_COOKIE_NAME } from '../../../../constants';
import { AnnotationResult, RSAutocompleteValue } from '../../../../types';
import {
  OperationalLifeCycleType,
  browserTimezone,
  filterValidUrlFields,
  mapAnnotationResultDisplayLabel,
  mapOperationalLifeCycleDisplayLabel,
  validateTimezone
} from '../../../../utilities';
import { FilterPanelButtonsGroup } from '../../../4-features';
import { RSActionLink, RSAutocomplete, RSAutocompleteDefaultMenuOption } from '../../../5-elements';
import { IN_PROGRESS_CODE, operationsFilterFields } from '../generate-queries';
import {
  OperationsFilters,
  OperationsOverviewSearchParameters,
  operationsFilterSchema,
  operationsOverviewStatesSchema
} from '../operations-overview-states-schema';

interface OperationsFilterPanelProps {
  serialNumbers?: GetSerialNumbersQuery;
  operationResults?: GetOperationResultsQuery;
  customerNames?: GetCustomerNamesQuery;
  siteNames?: GetSiteNamesQuery;
  programNames?: GetProgramNamesQuery;
  defaultValues: OperationsFilters;
}

export const OperationsFilterPanel = ({
  serialNumbers,
  operationResults,
  customerNames,
  siteNames,
  programNames,
  defaultValues
}: OperationsFilterPanelProps): JSX.Element => {
  const { t } = useTranslation();
  const [cookies] = useCookies([TIMEZONE_COOKIE_NAME]);
  const routerLocation = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const {
    control,
    handleSubmit,
    formState: { errors, isDirty },
    reset,
    getValues,
    setValue
  } = useForm<OperationsFilters>({
    resolver: zodResolver(operationsFilterSchema),
    defaultValues
  });
  const serialNumberOptions = (serialNumbers?.devices.map((device) => device.serialNumber) as string[]) || [];
  const operationResultOptions =
    operationResults?.deviceOperationResults.map((operationResult) => operationResult.code) || [];
  const annotationOptions = Object.values(AnnotationResult);
  const operationalLifeCycleOptions = Object.values(OperationalLifeCycle);
  const customerNameOptions = customerNames?.customers.map((customer) => customer.company.name) || [];
  const siteNameOptions = siteNames?.sites.map((site) => site.name) || [];
  const programNameOptions = programNames?.programs.map((program) => program.name) || [];
  const operationResultOptionsWithInProgress = [IN_PROGRESS_CODE, ...operationResultOptions];
  const successResultObjects = operationResults?.deviceOperationResults.filter((item) => item.isSuccessful) || [];
  const successResultCodes = successResultObjects.map((item) => item.code);
  const failedResultObjects = operationResults?.deviceOperationResults.filter((item) => !item.isSuccessful) || [];
  const failedResultCodes = failedResultObjects.map((item) => item.code);

  const annotationDisplayOption = (option: RSAutocompleteValue): JSX.Element => {
    return <RSAutocompleteDefaultMenuOption option={mapAnnotationResultDisplayLabel(option as AnnotationResult)!} />;
  };
  const annotationTagDisplayOption = (option: RSAutocompleteValue) =>
    mapAnnotationResultDisplayLabel(option as AnnotationResult);
  const operationalLifeCycleMenuDisplayOption = (option: RSAutocompleteValue): JSX.Element => {
    return (
      <RSAutocompleteDefaultMenuOption
        option={mapOperationalLifeCycleDisplayLabel(option as OperationalLifeCycleType)!}
      />
    );
  };
  const operationalLifeCycleTagDisplayOption = (option: RSAutocompleteValue) =>
    mapOperationalLifeCycleDisplayLabel(option as OperationalLifeCycleType);

  const resultDisplayTag = (option: RSAutocompleteValue): string => {
    const resultObject = operationResults?.deviceOperationResults.find((item) => item.code === option);
    const readableResult = resultObject?.name;
    return readableResult || t('operationsPage.results.inProgress');
  };

  const onSubmit: SubmitHandler<OperationsFilters> = (data): void => {
    // Convert the date from the date picker field to the user-selected time zone.
    // Although the date picker field has a `timezone` property, but it does not update when the timezone in the cookie
    // changes. Therefore we do the timezone conversion here.
    const dateTimeData = data.dateTime as (DateTime<boolean>[] | null) | undefined;
    // Get the "plain text" date right from the date picker.
    const datesInString =
      dateTimeData && every(dateTimeData, (dateTimeItem) => dateTimeItem instanceof DateTime)
        ? dateTimeData.map((luxonDateTime) => luxonDateTime.toISODate())
        : undefined;
    const timezoneForQuery = validateTimezone(cookies[TIMEZONE_COOKIE_NAME])
      ? cookies[TIMEZONE_COOKIE_NAME]
      : browserTimezone;
    // Assign the user-selected timezone for the date string
    const startAtFrom = datesInString && DateTime.fromISO(datesInString[0]!, { zone: timezoneForQuery }).toISO();
    const startAtTo =
      datesInString &&
      DateTime.fromISO(datesInString[1]!, { zone: timezoneForQuery })
        .plus({ day: 1 })
        .minus({ millisecond: 1 })
        .toISO();
    const dateTimeQuery = datesInString && [startAtFrom, startAtTo];
    const newSearchParams = {
      ...queryString.parse(searchParams.toString(), { arrayFormat: 'comma' }),
      ...data,
      dateTime: dateTimeQuery,
      page: '1'
    };
    setSearchParams(new URLSearchParams(queryString.stringify(newSearchParams, { arrayFormat: 'comma' })));
    reset(data);
  };

  const onReset: SubmitHandler<OperationsFilters> = (): void => {
    const searchParamsObject = queryString.parse(searchParams.toString(), {
      arrayFormat: 'comma'
    });
    const searchParamsObjectNoFilter = omit(searchParamsObject, operationsFilterFields);
    searchParamsObjectNoFilter.page = '1';
    setSearchParams(new URLSearchParams(queryString.stringify(searchParamsObjectNoFilter, { arrayFormat: 'comma' })));
    reset({
      serialNumber: [],
      dateTime: [null, null],
      result: [],
      annotation: [],
      operationalLifeCycle: [],
      customer: [],
      site: [],
      program: []
    });
  };

  useEffect(() => {
    const searchParameters = queryString.parse(searchParams.toString(), { arrayFormat: 'comma' });
    const validUrlFields = filterValidUrlFields<OperationsOverviewSearchParameters>(
      searchParameters,
      operationsOverviewStatesSchema
    );

    const filterParameters = pick(validUrlFields, operationsFilterFields);
    const resetObject: OperationsFilters = {
      serialNumber: filterParameters.serialNumber || [],
      site: filterParameters.site || [],
      operationalLifeCycle: filterParameters.operationalLifeCycle || [],
      customer: filterParameters.customer || [],
      program: filterParameters.program || [],
      result: filterParameters.result || [],
      annotation: filterParameters.annotation || [],
      dateTime: filterParameters.dateTime
        ? filterParameters.dateTime.map((stringDateTime) => DateTime.fromISO(stringDateTime))
        : [null, null]
    };
    if (!isEqual(getValues(), resetObject)) {
      reset(resetObject);
    }
  }, [routerLocation.search]);

  return (
    <aside className="operations-filter-panel" data-testid="operations-filter-panel">
      <form className="operations-filter-panel__form" onSubmit={handleSubmit(onSubmit)}>
        <div className="operations-filter-panel__filters">
          <Controller
            name="serialNumber"
            control={control}
            render={({ field: { onChange, onBlur, value } }) => {
              return (
                <RSAutocomplete
                  inputLabel={t('operationsPage.tableColumns.serialNumber')}
                  data-testid="operations-serial-number-autocomplete"
                  options={serialNumberOptions}
                  onChange={(_event, values) => onChange(values)}
                  value={filter(value, (valueItem) => includes(serialNumberOptions, valueItem))}
                  onBlur={onBlur}
                />
              );
            }}
          />
          <Controller
            name="result"
            control={control}
            render={({ field: { onChange, onBlur, value } }) => {
              return (
                <div className="operations-filter-panel__autocomplete-result">
                  <RSAutocomplete
                    inputLabel={t('operationsPage.tableColumns.result')}
                    data-testid="operations-result-autocomplete"
                    customMenuOption={getOperationResultDisplayOption(operationResults)}
                    customTagOption={resultDisplayTag}
                    options={operationResultOptionsWithInProgress}
                    onChange={(_event, values) => onChange(values)}
                    value={filter(value, (valueItem) => includes(operationResultOptionsWithInProgress, valueItem))}
                    onBlur={onBlur}
                  />
                  <div className="operations-filter-panel__autocomplete-result-presets">
                    <span>{t('operationsPage.filterPanel.resultPresets.select')}</span>
                    <RSActionLink
                      extraClassNames={['operations-filter-panel__autocomplete-result-preset-option']}
                      data-testid="operation-filter-panel-result-preset-all-success"
                      handleClick={(event) => {
                        event.preventDefault(); // Not do this will submit the form
                        const currentValue = value || [];
                        const updatedValue = uniq([...currentValue, ...successResultCodes]);
                        setValue('result', updatedValue, { shouldDirty: true, shouldTouch: true });
                      }}
                    >
                      {t('operationsPage.filterPanel.resultPresets.allSuccess')}
                    </RSActionLink>
                    <span>{t('operationsPage.filterPanel.resultPresets.divider')}</span>
                    <RSActionLink
                      extraClassNames={['operations-filter-panel__autocomplete-result-preset-option']}
                      data-testid="operation-filter-panel-result-preset-all-failed"
                      handleClick={(event) => {
                        event.preventDefault(); // Not do this will submit the form
                        const currentValue = value || [];
                        const updatedValue = uniq([...currentValue, ...failedResultCodes]);
                        setValue('result', updatedValue, { shouldDirty: true, shouldTouch: true });
                      }}
                    >
                      {t('operationsPage.filterPanel.resultPresets.allFailed')}
                    </RSActionLink>
                  </div>
                </div>
              );
            }}
          />
          <Controller
            name="annotation"
            control={control}
            render={({ field: { onChange, onBlur, value } }) => {
              return (
                <RSAutocomplete
                  inputLabel={t('operationsPage.filterPanel.annotation')}
                  data-testid="operations-annotation-autocomplete"
                  customMenuOption={annotationDisplayOption}
                  customTagOption={annotationTagDisplayOption}
                  options={annotationOptions}
                  onChange={(_event, values) => onChange(values)}
                  value={filter(value, (valueItem) => includes(annotationOptions, valueItem))}
                  onBlur={onBlur}
                />
              );
            }}
          />
          <Controller
            name="dateTime"
            control={control}
            render={({ field: { onChange, value } }) => {
              return (
                <OperationsFilterDatePicker
                  onChange={onChange}
                  value={value}
                  timezone={cookies[TIMEZONE_COOKIE_NAME]}
                  errors={errors}
                />
              );
            }}
          />
          <Controller
            name="operationalLifeCycle"
            control={control}
            render={({ field: { onChange, onBlur, value } }) => {
              return (
                <RSAutocomplete
                  inputLabel={t('operationsPage.tableColumns.operationalLifeCycle')}
                  data-testid="operations-operational-life-cycle-autocomplete"
                  options={operationalLifeCycleOptions}
                  customMenuOption={operationalLifeCycleMenuDisplayOption}
                  customTagOption={operationalLifeCycleTagDisplayOption}
                  onChange={(_event, values) => onChange(values)}
                  value={filter(value, (valueItem) => includes(operationalLifeCycleOptions, valueItem))}
                  onBlur={onBlur}
                />
              );
            }}
          />
          <Controller
            name="customer"
            control={control}
            render={({ field: { onChange, onBlur, value } }) => {
              return (
                <RSAutocomplete
                  inputLabel={t('operationsPage.tableColumns.customer')}
                  data-testid="operations-customer-autocomplete"
                  options={customerNameOptions}
                  onChange={(_event, values) => onChange(values)}
                  value={filter(value, (valueItem) => includes(customerNameOptions, valueItem))}
                  onBlur={onBlur}
                />
              );
            }}
          />
          <Controller
            name="site"
            control={control}
            render={({ field: { onChange, onBlur, value } }) => {
              return (
                <RSAutocomplete
                  inputLabel={t('operationsPage.tableColumns.site')}
                  data-testid="operations-site-autocomplete"
                  options={siteNameOptions}
                  onChange={(_event, values) => onChange(values)}
                  value={filter(value, (valueItem) => includes(siteNameOptions, valueItem))}
                  onBlur={onBlur}
                />
              );
            }}
          />
          <Controller
            name="program"
            control={control}
            render={({ field: { onChange, onBlur, value } }) => {
              return (
                <RSAutocomplete
                  inputLabel={t('operationsPage.tableColumns.program')}
                  data-testid="operations-program-autocomplete"
                  options={programNameOptions}
                  onChange={(_event, values) => onChange(values)}
                  value={filter(value, (valueItem) => includes(programNameOptions, valueItem))}
                  onBlur={onBlur}
                />
              );
            }}
          />
        </div>
        <FilterPanelButtonsGroup
          handleApply={handleSubmit(onSubmit)}
          handleClearAll={handleSubmit(onReset)}
          isApplyDisabled={!isDirty}
        />
      </form>
    </aside>
  );
};
