import { useQuery } from '@apollo/client';
import { t } from 'i18next';
import { JSX, useState } from 'react';
import { useParams } from 'react-router-dom';

import { DeviceDetailsSidePanel } from './device-details-side-panel';
import { DeviceHealthCards } from './device-health-cards';
import { DeviceLatestOperations } from './device-latest-operations';
import { generateDeviceDetailsPerformanceMetricsFilter } from './generate-queries';
import ArrowLeftIcon from '../../../assets/icons/arrow-left.svg?react';
import { appConfig } from '../../../configs';
import { useAuthCheckerWithSubjectInfo } from '../../../services/authz-checker';
import { QUERY_GET_DEVICE_DETAILS } from '../../../services/queries';
import { DetailsMainSectionWrapper } from '../../2-templates';
import { OperationDetails, OperationDetailsBasicInformation } from '../../3-sections';
import { PerformanceMetrics } from '../../4-features';
import { InternalLink } from '../../5-elements';
import { ErrorPage } from '../error-page';
import { PageNotFoundErrorPage } from '../error-page/page-not-found-error-page';
import { LoadingPage } from '../loading-page';

export const DeviceDetailsPage = (): JSX.Element => {
  const { deviceId } = useParams();
  const { basePath } = appConfig;
  const [operationDetailsOpen, setOperationDetailsOpen] = useState<boolean>(false);
  const [selectedOperation, setSelectedOperation] = useState<OperationDetailsBasicInformation | undefined>(undefined);
  const { loading, data, error } = useQuery(QUERY_GET_DEVICE_DETAILS, {
    variables: { deviceId: deviceId! }
  });
  // Put the auth checker here so that it can be part of the loading and error handling flow. It will be used for both
  // the site impression and the edit device components.
  const { result: userCanEditDevice, loading: loadingUserCanEditDevice } = useAuthCheckerWithSubjectInfo({
    action: 'UPDATE',
    subjectInfo: {
      type: 'Device',
      deviceId: deviceId || ''
    },
    skip: !deviceId
  });

  const handleOperationDetailsClose = (): void => {
    setOperationDetailsOpen(false);
    setSelectedOperation(undefined);
  };

  if (error) {
    const isUUIDError = error.name === 'ApolloError' && error.message.includes('invalid input syntax for type uuid');

    if (isUUIDError) {
      return <PageNotFoundErrorPage />;
    }

    return (
      <ErrorPage
        titleEmphasized={t('apolloErrorPage.errorCode')}
        title={t('apolloErrorPage.errorTitle')}
        message={error.message}
      />
    );
  }

  const isLoading = loading || loadingUserCanEditDevice || !data;

  if (isLoading) {
    return <LoadingPage />;
  }

  const device = data?.deviceByPK;

  if (!device) {
    return <PageNotFoundErrorPage />;
  }

  return (
    <DetailsMainSectionWrapper panel={<DeviceDetailsSidePanel device={device} canUserEditDevice={userCanEditDevice} />}>
      <section className="device-details-page" data-testid="device-details-page">
        <div className="device-details-page__device-health">
          <div className="device-details-page__title-bar">
            <h2 className="device-details-page__system-health-status">{t('deviceDetailsPage.deviceHealth.title')}</h2>
            <PerformanceMetrics
              layout="align-right"
              filters={generateDeviceDetailsPerformanceMetricsFilter(deviceId!)}
            />
          </div>
          <DeviceHealthCards
            deviceLastState={device.deviceLastState}
            deviceMeasurementValues={device.deviceMeasurementValues}
          />
        </div>
        <div className="device-details-page__operations">
          <div className="device-details-page__operations-title-bar">
            <h3>{t('deviceDetailsPage.operations.title')}</h3>
            <InternalLink
              to={`${basePath}/operations?serialNumber=${device.serialNumber}`}
              icon={<ArrowLeftIcon />}
              text={t('deviceDetailsPage.operations.allOperationsLinkText')}
              iconPosition="right"
            />
          </div>
          <div className="device-details-page__data-grid">
            <DeviceLatestOperations
              operations={device.deviceOperations}
              serialNumber={device.serialNumber}
              setSelectedOperation={setSelectedOperation}
              setOperationDetailsOpen={setOperationDetailsOpen}
              customerName={device.site.customer.company.name}
              siteName={device.site.name}
              programNumber={device.program?.name}
              customerId={device.site.customer.companyId}
            />
          </div>
        </div>
        <OperationDetails
          operationId={selectedOperation?.id}
          open={operationDetailsOpen}
          handleOperationDetailsClose={handleOperationDetailsClose}
        />
      </section>
    </DetailsMainSectionWrapper>
  );
};
