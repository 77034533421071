import { CircularProgress } from '@mui/material';
import { JSX, MouseEventHandler } from 'react';
import { useTranslation } from 'react-i18next';

import BlobUploadIcon from '../../../../assets/icons/blob-upload.svg?react';
import { ObjectUploadStatus } from '../../../../types';
import { mapObjectUploadStatusDescription } from '../../../../utilities';
import { RSButton } from '../../../5-elements';

interface OperationLogFetchSkippedProps {
  /* Whether there is an error when requesting the log URL */
  hasErrorLogUrl: boolean;
  /* The device online status */
  isDeviceOnline: boolean;
  /* The log upload status */
  logStatus: ObjectUploadStatus;
  /* Whether the portal is requesting the log */
  requestingLogStatus: boolean;
  /* The method to handle log request from the remote server */
  handleRequestLog: MouseEventHandler<HTMLButtonElement>;
}

export const OperationLogFetchSkipped = ({
  hasErrorLogUrl,
  isDeviceOnline,
  logStatus,
  requestingLogStatus,
  handleRequestLog
}: OperationLogFetchSkippedProps): JSX.Element => {
  const { t } = useTranslation();
  const generateLogFetchStatusMessage = (
    hasErrorLogUrl: boolean,
    logStatus: ObjectUploadStatus,
    isDeviceOnline: boolean
  ): string => {
    if (hasErrorLogUrl) {
      return t('operationsPage.operationDetails.logModal.urlRequestError');
    }
    if (!isDeviceOnline) {
      return t('operationsPage.operationDetails.logModal.deviceOffline');
    }
    return mapObjectUploadStatusDescription(logStatus);
  };

  return (
    <div className="operation-log-fetch-skipped" data-testid="operation-log-fetch-skipped">
      <div className="operation-log-fetch-skipped__icon-container">
        <BlobUploadIcon />
      </div>
      <h3 className="operation-log-fetch-skipped__title">
        {t('operationsPage.operationDetails.logModal.unavailable')}
      </h3>
      <span className="operation-log-fetch-skipped__status" data-testid="operation-log-fetch-skipped-status">
        {generateLogFetchStatusMessage(hasErrorLogUrl, logStatus, isDeviceOnline)}
      </span>
      {/*
        If the log is being requested, or the device is offline, or the log can no longer be fetched, the request
        button will be disabled
      */}
      <RSButton
        variant="contained"
        disabled={
          requestingLogStatus ||
          !isDeviceOnline ||
          logStatus === ObjectUploadStatus.UploadFailed ||
          logStatus === ObjectUploadStatus.UploadFailedNotFound
        }
        onClick={handleRequestLog}
        data-testid="operation-log-request-button"
      >
        {requestingLogStatus ? (
          <span className="operation-log-fetch-skipped__button-requesting">
            <CircularProgress size={16} />
            {t('operationsPage.operationDetails.logModal.requesting')}
          </span>
        ) : (
          t('operationsPage.operationDetails.logModal.requestLog')
        )}
      </RSButton>
    </div>
  );
};
