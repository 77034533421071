import { isEmpty, uniq } from 'lodash';

import { DeviceBoolExp } from '../../../../__generated__/graphql';
import { DevicesOverviewSearchParameters } from '../devices-overview-states-schema';

export const deviceFilterFields = [
  'serialNumber',
  'operationalLifeCycle',
  'connectorType',
  'customer',
  'rocOS',
  'site',
  'program'
] as const;

type DevicesFilterField = (typeof deviceFilterFields)[number];

export const generateFilterQuery = (
  filterParameters: Pick<DevicesOverviewSearchParameters, DevicesFilterField>
): DeviceBoolExp => {
  const filterQuery: DeviceBoolExp = { _and: [] };
  if (filterParameters.serialNumber) {
    filterQuery._and!.push({ serialNumber: { _in: uniq(filterParameters.serialNumber) } });
  }
  if (filterParameters.operationalLifeCycle) {
    filterQuery._and!.push({ operationalLifeCycle: { _in: uniq(filterParameters.operationalLifeCycle) } });
  }
  if (filterParameters.connectorType) {
    filterQuery._and!.push({ connectorHolderType: { connectorType: { _in: uniq(filterParameters.connectorType) } } });
  }
  if (filterParameters.customer) {
    filterQuery._and!.push({ site: { customer: { company: { name: { _in: uniq(filterParameters.customer) } } } } });
  }
  if (filterParameters.rocOS) {
    filterQuery._and!.push({
      deviceSoftwareConfigurationActive: { rocosComposedVersion: { _in: uniq(filterParameters.rocOS) } }
    });
  }
  if (filterParameters.site) {
    filterQuery._and!.push({ site: { name: { _in: uniq(filterParameters.site) } } });
  }
  if (filterParameters.program) {
    filterQuery._and!.push({ program: { name: { _in: uniq(filterParameters.program) } } });
  }

  if (isEmpty(filterQuery._and)) {
    return {};
  }
  return filterQuery;
};
