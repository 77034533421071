import { useMutation } from '@apollo/client';
import { isEmpty, isNil } from 'lodash';
import { useSnackbar } from 'notistack';
import { Dispatch, JSX, MouseEventHandler, SetStateAction } from 'react';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';

import { MainOperationEdit } from './main-operation-edit';
import { GetOperationDetailsQuery, OperationalLifeCycle } from '../../../../__generated__/graphql';
import { graphqlApiConfig } from '../../../../configs';
import { TIMEZONE_COOKIE_NAME } from '../../../../constants';
import { MUTATION_MODIFY_DEVICE_OPERATION } from '../../../../services/mutations';
import { SnackbarMessageType } from '../../../../types';
import {
  calculateDuration,
  constructSnackbarMessage,
  generateOperationResultLabel,
  getNonNullishDisplayValue,
  mapOperationalLifeCycleDisplayLabel
} from '../../../../utilities';
import { EditButton, OperationAnnotationStatus, OperationResultChip } from '../../../4-features';
import { InformationBlock, LongTextWithExpansion, RSActionLink, RSButton } from '../../../5-elements';
import { formatOperationDate, formatOperationTime } from '../format-operation-date-time';

interface MainOperationProps {
  deviceOperation: NonNullable<GetOperationDetailsQuery['deviceOperationByPK']>;
  handleAnnotationChangeLinkClick: MouseEventHandler<HTMLButtonElement>;
  canUserEditOperation?: boolean;
  editMode: boolean;
  setEditMode: Dispatch<SetStateAction<boolean>>;
}

export const MainOperation = ({
  deviceOperation,
  handleAnnotationChangeLinkClick,
  canUserEditOperation,
  editMode,
  setEditMode
}: MainOperationProps): JSX.Element => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const sendMessageToSnackbar = (...args: SnackbarMessageType): void => {
    enqueueSnackbar(constructSnackbarMessage(...args));
  };
  const [cookies] = useCookies([TIMEZONE_COOKIE_NAME]);
  const [modifyOperation, { loading }] = useMutation(MUTATION_MODIFY_DEVICE_OPERATION, {
    context: { timeout: graphqlApiConfig.mutationTimeout },
    onError: (error) => {
      sendMessageToSnackbar(t('forms.snackbarMessages.errorSave'), undefined, error.message || error.name, 'error');
    }
  });

  return (
    <div className="main-operation" data-testid="main-operation">
      <div className="main-operation__edit">
        <EditButton
          disabled={
            !canUserEditOperation || isNil(deviceOperation.deviceOperationFinalResult?.isSuccessful) || editMode
          }
          data-testid="main-operation-edit-button"
          handleClick={() => setEditMode(true)}
        />
      </div>
      {editMode && (
        <MainOperationEdit
          defaultValues={{
            id: deviceOperation.id,
            operationalLifeCycle: deviceOperation.operationalLifeCycle as OperationalLifeCycle,
            remark: deviceOperation.remark,
            annotatedResultCode: deviceOperation.deviceOperationFinalResult?.code
          }}
          setEditMode={setEditMode}
        />
      )}
      {!editMode && (
        <>
          <div className="main-operation__operation-result-annotation">
            <div className="main-operation__result">
              <InformationBlock
                label={t('operationsPage.operationDetails.titles.result')}
                value={
                  <OperationResultChip
                    title={generateOperationResultLabel(deviceOperation)}
                    label={generateOperationResultLabel(deviceOperation)}
                    hasEndedAt={Boolean(deviceOperation.endAt)}
                    success={deviceOperation.deviceOperationFinalResult?.isSuccessful}
                  />
                }
              />
              {canUserEditOperation && !deviceOperation.deviceOperationAnnotatedResult && (
                <RSButton
                  // Disabled if the mutation is executing, or the operation is still in progress
                  disabled={loading || isNil(deviceOperation.deviceOperationFinalResult?.code)}
                  variant="contained"
                  extraClassNames={['main-operation__confirm-result-button']}
                  data-testid="main-operation-confirm-annotation-result"
                  onClick={() =>
                    modifyOperation({
                      variables: {
                        deviceOperationInput: {
                          id: deviceOperation.id,
                          annotatedResultCode: deviceOperation.deviceOperationResult?.code
                        }
                      },
                      onCompleted: () =>
                        sendMessageToSnackbar(
                          t('forms.snackbarMessages.successfullySaved'),
                          undefined,
                          undefined,
                          'success'
                        )
                    })
                  }
                >
                  {t('operationsPage.operationDetails.annotationHistory.confirmResult')}
                </RSButton>
              )}
            </div>
            <div className="main-operation__life-cycle-annotation">
              <InformationBlock
                label={t('operationsPage.operationDetails.titles.operationalLifeCycle')}
                value={mapOperationalLifeCycleDisplayLabel(deviceOperation.operationalLifeCycle)}
              />
              <InformationBlock
                label={t('operationsPage.operationDetails.titles.annotationStatus')}
                value={
                  <OperationAnnotationStatus
                    deviceAnnotatedResultId={deviceOperation.deviceOperationAnnotatedResult?.id}
                    deviceResultId={deviceOperation.deviceOperationResult?.id}
                  />
                }
              />
              {!isEmpty(deviceOperation.deviceOperationAnnotationAudits) && (
                <RSActionLink
                  handleClick={handleAnnotationChangeLinkClick}
                  extraClassNames={['main-operation__annotation-history-button']}
                  data-testid="annotation-change-history-link"
                >
                  {t('operationsPage.operationDetails.annotationHistory.actionLink')}
                </RSActionLink>
              )}
            </div>
            <div className="main-operation__notes">
              <InformationBlock
                label={t('operationsPage.operationDetails.titles.notes')}
                value={
                  <LongTextWithExpansion
                    amountOfTextLines={3}
                    condensedTextHeight={64}
                    text={getNonNullishDisplayValue(deviceOperation.remark)}
                    moreTextBtnText={t('longText.showMore')}
                    lessTextBtnText={t('longText.showLess')}
                  />
                }
              />
            </div>
          </div>
        </>
      )}
      <div className="main-operation__time" data-testid="operation-details-time">
        <InformationBlock
          label={t('operationsPage.operationDetails.titles.date')}
          value={formatOperationDate(deviceOperation.startAt, deviceOperation.endAt, cookies[TIMEZONE_COOKIE_NAME])}
        />
        <InformationBlock
          label={t('operationsPage.operationDetails.titles.duration')}
          value={calculateDuration(deviceOperation.startAt, deviceOperation.endAt)}
        />
        <InformationBlock
          label={t('operationsPage.operationDetails.titles.startAt')}
          value={formatOperationTime(deviceOperation.startAt, cookies[TIMEZONE_COOKIE_NAME])}
        />
        <InformationBlock
          label={t('operationsPage.operationDetails.titles.endAt')}
          value={formatOperationTime(deviceOperation.endAt, cookies[TIMEZONE_COOKIE_NAME])}
        />
      </div>
    </div>
  );
};
