import classNames from 'classnames';
import { isNil } from 'lodash';
import { JSX } from 'react';
import { useTranslation } from 'react-i18next';

import AttentionRequiredIcon from '../../../../assets/icons/status-attention-required.svg?react';
import CriticalIcon from '../../../../assets/icons/status-critical.svg?react';
import ImprovementPossibleIcon from '../../../../assets/icons/status-improvement-possible.svg?react';
import MaintenanceRequiredIcon from '../../../../assets/icons/status-maintenance-required.svg?react';
import NoMonitoringActiveIcon from '../../../../assets/icons/status-no-monitoring-active.svg?react';
import StatusOkIcon from '../../../../assets/icons/status-ok.svg?react';

export type DeviceStatusIconVariant =
  | 'normal'
  | 'critical'
  | 'improvement-possible'
  | 'attention-required'
  | 'maintenance-required'
  | 'no-monitoring-active';

interface DeviceStatusIconProps {
  statusIconVariant?: DeviceStatusIconVariant;
}

interface DeviceStatusProps extends DeviceStatusIconProps {
  statusValue?: string | number | null;
  stateName?: string | null;
  // sizeVariant: 'normal' takes a height of 24 px, 'small' 17 px.
  sizeVariant?: 'small' | 'normal';
}

export const DeviceStatusIcon = ({ statusIconVariant }: DeviceStatusIconProps): JSX.Element => {
  switch (statusIconVariant) {
    case 'normal':
      return <StatusOkIcon data-testid="device-status-icon-ok" />;
    case 'attention-required':
      return <AttentionRequiredIcon data-testid="device-status-icon-attention-required" />;
    case 'critical':
      return <CriticalIcon data-testid="device-status-icon-critical" />;
    case 'improvement-possible':
      return <ImprovementPossibleIcon data-testid="device-status-icon-improvement-possible" />;
    case 'maintenance-required':
      return <MaintenanceRequiredIcon data-testid="device-status-icon-maintenance-required" />;
    default:
      return <NoMonitoringActiveIcon data-testid="device-status-icon-no-monitoring-active" />;
  }
};

export const DeviceStatus = (props: DeviceStatusProps): JSX.Element => {
  const { t } = useTranslation();
  const iconExtraClassName = props.statusIconVariant || 'no-monitoring-active';
  const iconClassNames = classNames('device-status__icon', `device-status__icon--${iconExtraClassName}`, {
    'device-status__icon--small': props.sizeVariant === 'small'
  });
  const statusValueClassNames = classNames('device-status__value', {
    'device-status__value--small': props.sizeVariant === 'small'
  });

  return (
    <div className="device-status">
      <div className="device-status__value-container">
        <div className={iconClassNames}>
          <DeviceStatusIcon statusIconVariant={props.statusIconVariant} />
        </div>
        <p className={statusValueClassNames} data-testid="device-status-value">
          {isNil(props.statusValue) ? t('deviceDetailsPage.deviceHealth.unknown') : props.statusValue}
        </p>
      </div>
      {props.stateName && (
        <p className="device-status__state-name" data-testid="device-status-state-name">
          {props.stateName}
        </p>
      )}
    </div>
  );
};
