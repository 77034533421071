import { GetOperationDetailsQuery } from '../__generated__/graphql';

export type AnnotationAuditHistory = NonNullable<
  GetOperationDetailsQuery['deviceOperationByPK']
>['deviceOperationAnnotationAudits'];

export type OriginalOperationResult = NonNullable<
  GetOperationDetailsQuery['deviceOperationByPK']
>['deviceOperationResult'];

export type InitialOperationalLifeCycle = NonNullable<
  GetOperationDetailsQuery['deviceOperationByPK']
>['initialOperationalLifeCycle'];

export enum AnnotationResult {
  Annotated = 'ANNOTATED',
  NotAnnotated = 'NOT_ANNOTATED'
}
