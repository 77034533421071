import { CircularProgress } from '@mui/material';
import { JSX, MouseEventHandler } from 'react';
import { useTranslation } from 'react-i18next';

import BlobUploadIcon from '../../../../assets/icons/blob-upload.svg?react';
import { ObjectUploadStatus } from '../../../../types';
import { mapObjectUploadStatusDescription } from '../../../../utilities';
import { RSButton } from '../../../5-elements';

interface OperationImageFetchSkippedProps {
  /* Whether there is an error when requesting the image URL */
  hasErrorImageUrl: boolean;
  /* The device online status */
  isDeviceOnline: boolean;
  /* The image upload status */
  imageStatus: ObjectUploadStatus;
  /* Whether the portal is requesting the image */
  requestingImageStatus: boolean;
  /* The method to handle image request from the remote server */
  handleRequestImage: MouseEventHandler<HTMLButtonElement>;
}

export const OperationImageFetchSkipped = ({
  hasErrorImageUrl,
  isDeviceOnline,
  imageStatus,
  requestingImageStatus,
  handleRequestImage
}: OperationImageFetchSkippedProps): JSX.Element => {
  const { t } = useTranslation();
  const generateImageFetchStatusMessage = (
    hasErrorImageUrl: boolean,
    imageStatus: ObjectUploadStatus,
    isDeviceOnline: boolean
  ): string => {
    if (hasErrorImageUrl) {
      return t('operationsPage.operationDetails.imageModal.urlRequestError');
    }
    if (!isDeviceOnline) {
      return t('operationsPage.operationDetails.imageModal.deviceOffline');
    }
    return mapObjectUploadStatusDescription(imageStatus);
  };

  return (
    <div className="operation-image-fetch-skipped" data-testid="operation-image-fetch-skipped">
      <div className="operation-image-fetch-skipped__icon-container">
        <BlobUploadIcon />
      </div>
      <h3 className="operation-image-fetch-skipped__title">
        {t('operationsPage.operationDetails.imageModal.unavailable')}
      </h3>
      <span className="operation-image-fetch-skipped__status" data-testid="operation-image-fetch-skipped-status">
        {generateImageFetchStatusMessage(hasErrorImageUrl, imageStatus, isDeviceOnline)}
      </span>
      {/*
        If the image is being requested, or the device is offline, or the image can no longer be fetched, the request
        button will be disabled
      */}
      <RSButton
        variant="contained"
        disabled={
          requestingImageStatus ||
          !isDeviceOnline ||
          imageStatus === ObjectUploadStatus.UploadFailed ||
          imageStatus === ObjectUploadStatus.UploadFailedNotFound
        }
        onClick={handleRequestImage}
        data-testid="operation-image-request-button"
      >
        {requestingImageStatus && isDeviceOnline ? (
          <span className="operation-image-fetch-skipped__button-requesting">
            <CircularProgress size={16} />
            {t('operationsPage.operationDetails.imageModal.requesting')}
          </span>
        ) : (
          t('operationsPage.operationDetails.imageModal.requestImage')
        )}
      </RSButton>
    </div>
  );
};
