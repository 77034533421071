import { GetDevicesQuery } from '../../../../__generated__/graphql';
import { FlattenFunction } from '../../../../types';
import { mapOperationalLifeCycleDisplayLabel } from '../../../../utilities';

export type DeviceRowDevice = FlattenFunction<typeof generateDeviceRows>;

export const generateDeviceRows = (data?: GetDevicesQuery) => {
  if (!data) {
    return [];
  }

  return data.devices.map((device) => ({
    id: device.id,
    serialNumber: device.serialNumber,
    operationalPerformance: {
      acdCycleOperationsSuccessRate: device.deviceCurrentPerformance?.performanceLastPeriodPct ?? null,
      acdCycleOperationsSuccessRateChange: device.deviceCurrentPerformance?.performanceDiff ?? null,
      acdCycleOperationsCount: device.deviceCurrentPerformance?.totalLastPeriod ?? null,
      acdCycleOperationsCountChange: device.deviceCurrentPerformance?.totalDiffPct ?? null
    },
    operationalLifeCycle: mapOperationalLifeCycleDisplayLabel(device.operationalLifeCycle),
    rocOS: device.deviceSoftwareConfigurationActive?.rocosComposedVersion,
    connectorType: device.connectorHolderType?.connectorType,
    customer: device.site.customer.company.name,
    site: device.site.name,
    program: device.program?.name,
    customerId: device.site.customer.company.id
  }));
};
