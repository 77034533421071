import { includes, uniq } from 'lodash';
import { DateTime } from 'luxon';

import { DeviceOperationBoolExp } from '../../../../__generated__/graphql';
import { DEFAULT_GET_OPERATIONS_FILTER } from '../../../../constants';
import { AnnotationResult } from '../../../../types';
import { OperationsOverviewSearchParameters } from '../operations-overview-states-schema';

export const operationsFilterFields = [
  'serialNumber',
  'result',
  'annotation',
  'dateTime',
  'operationalLifeCycle',
  'customer',
  'site',
  'program'
] as const;

type OperationsFilterField = (typeof operationsFilterFields)[number];

export const IN_PROGRESS_CODE = 'IN_PROGRESS';

export const generateFilterQuery = (
  filterParameters: Pick<OperationsOverviewSearchParameters, OperationsFilterField>
): DeviceOperationBoolExp => {
  const filterQuery: DeviceOperationBoolExp = { _and: [DEFAULT_GET_OPERATIONS_FILTER] };
  if (filterParameters.serialNumber) {
    filterQuery._and!.push({ device: { serialNumber: { _in: uniq(filterParameters.serialNumber) } } });
  }
  if (filterParameters.result) {
    if (!includes(filterParameters.result, IN_PROGRESS_CODE)) {
      filterQuery._and!.push({ deviceOperationFinalResult: { code: { _in: uniq(filterParameters.result) } } });
      filterQuery._and!.push({ success: { _isNull: false } });
    } else {
      const codeNoInProgress = filterParameters.result.filter((code) => code !== IN_PROGRESS_CODE);
      filterQuery._or = [
        { success: { _isNull: true } },
        { deviceOperationFinalResult: { code: { _in: uniq(codeNoInProgress) } } }
      ];
    }
  }
  // The dateTime can only reach here if the input passed the validation.
  if (filterParameters.dateTime) {
    const startAtFrom = DateTime.fromISO(filterParameters.dateTime[0]).toISO();
    const startAtTo = DateTime.fromISO(filterParameters.dateTime[1]).toISO();
    filterQuery._and!.push({ startAt: { _gte: startAtFrom, _lt: startAtTo } });
  }
  if (filterParameters.operationalLifeCycle) {
    filterQuery._and!.push({ operationalLifeCycle: { _in: uniq(filterParameters.operationalLifeCycle) } });
  }
  if (filterParameters.annotation) {
    if (filterParameters.annotation.length === 1) {
      filterQuery._and!.push({
        deviceOperationAnnotatedResultId: {
          // if annotated: isNull = false; if not annotated: isNull = true
          _isNull: filterParameters.annotation[0] === AnnotationResult.NotAnnotated
        }
      });
    }
  }
  if (filterParameters.customer) {
    filterQuery._and!.push({
      device: { site: { customer: { company: { name: { _in: uniq(filterParameters.customer) } } } } }
    });
  }
  if (filterParameters.site) {
    filterQuery._and!.push({ device: { site: { name: { _in: uniq(filterParameters.site) } } } });
  }
  if (filterParameters.program) {
    filterQuery._and!.push({ device: { program: { name: { _in: uniq(filterParameters.program) } } } });
  }

  return filterQuery;
};
