import { GetDeviceDetailsQuery } from '../../../../__generated__/graphql';
import { Flatten } from '../../../../types';
import {
  calculateDuration,
  formatTimestamp,
  generateOperationNumber,
  generateOperationResultLabel,
  mapOperationalLifeCycleDisplayLabel
} from '../../../../utilities';
import { DataGridOperationResultCellProps } from '../../../4-features';

export type DeviceLatestOperationRow = Flatten<ReturnType<typeof generateDeviceLatestOperationRows>>;

export const generateDeviceLatestOperationRows = (
  operations: NonNullable<GetDeviceDetailsQuery['deviceByPK']>['deviceOperations'],
  serialNumber?: string | null,
  cookieTimezone?: string
) => {
  return operations.map((operation) => ({
    id: operation.id,
    serialNumber: generateOperationNumber(serialNumber || '', operation.sequence || 0),
    result: {
      deviceResultId: operation.deviceOperationResult?.id,
      deviceAnnotatedResultId: operation.deviceOperationAnnotatedResult?.id,
      success: operation.success,
      hasEndedAt: Boolean(operation.endAt),
      hasOperationResult: Boolean(operation.deviceOperationResult),
      label: generateOperationResultLabel(operation),
      title: generateOperationResultLabel(operation)
    } as DataGridOperationResultCellProps,
    dateTime: formatTimestamp(operation.startAt, cookieTimezone),
    duration: calculateDuration(operation.startAt, operation.endAt),
    operationalLifeCycle: mapOperationalLifeCycleDisplayLabel(operation.operationalLifeCycle)
  }));
};
