import { every, filter, isNil, toNumber } from 'lodash';
import { DateTime } from 'luxon';
import { z } from 'zod';

import { OperationalLifeCycle } from '../../../../__generated__/graphql';
import i18n from '../../../../i18n';
import { AnnotationResult } from '../../../../types';
import { PreprocessArgument, preprocessArrayStringInput } from '../../../../utilities';
import { OperationsOverviewSortOptions } from '../generate-queries';

// Base schema: everything except `dateTime`
const operationsBaseSchema = z.object({
  // pagination
  page: z.preprocess((arg) => toNumber(arg), z.number().int().positive()).optional(),
  // sorting
  sort: z.nativeEnum(OperationsOverviewSortOptions).optional(),
  // filtering
  serialNumber: z.preprocess(
    (arg) => preprocessArrayStringInput(arg as PreprocessArgument),
    z.string().array().optional()
  ),
  result: z.preprocess((arg) => preprocessArrayStringInput(arg as PreprocessArgument), z.string().array().optional()),
  annotation: z.preprocess(
    (arg) => preprocessArrayStringInput(arg as PreprocessArgument),
    z.nativeEnum(AnnotationResult).array().optional()
  ),
  operationalLifeCycle: z.preprocess(
    (arg) => preprocessArrayStringInput(arg as PreprocessArgument),
    z.nativeEnum(OperationalLifeCycle).array().optional()
  ),
  customer: z.preprocess((arg) => preprocessArrayStringInput(arg as PreprocessArgument), z.string().array().optional()),
  site: z.preprocess((arg) => preprocessArrayStringInput(arg as PreprocessArgument), z.string().array().optional()),
  program: z.preprocess((arg) => preprocessArrayStringInput(arg as PreprocessArgument), z.string().array().optional())
});

const handleDateTimeValidationError = (
  startedAt: DateTime<boolean>,
  endedAt: DateTime<boolean>,
  context: z.RefinementCtx
): void => {
  const dateTimeNow = DateTime.now();

  if (!startedAt.isValid || !endedAt.isValid) {
    context.addIssue({
      code: z.ZodIssueCode.custom,
      fatal: true,
      message: i18n.t('operationsPage.validations.invalidDate')
    });
  }

  if (endedAt > dateTimeNow.plus({ days: 1 }) || startedAt > dateTimeNow) {
    context.addIssue({
      code: z.ZodIssueCode.custom,
      fatal: true,
      message: i18n.t('operationsPage.validations.noFutureDate')
    });
  }

  if (startedAt > endedAt) {
    context.addIssue({
      code: z.ZodIssueCode.custom,
      fatal: true,
      message: i18n.t('operationsPage.validations.endedEarlierThanStart')
    });
  }
};

// This schema is used for the search query from the URL, therefore `dateTime` is a string.
export const operationsOverviewStatesSchema = operationsBaseSchema.extend({
  dateTime: z
    .string()
    .datetime({ offset: true })
    .array()
    .length(2)
    .optional()
    .superRefine((dateTime, context) => {
      if (dateTime) {
        const startedAt = DateTime.fromISO(dateTime[0]);
        const endedAt = DateTime.fromISO(dateTime[1]);

        handleDateTimeValidationError(startedAt, endedAt, context);
      }
    }),
  operationId: z.string().uuid().nullish()
});

// This schema is used for the filter form, in which (RSDateRangePicker) `dateTime` is a `luxon.Datetime` object.
export const operationsFilterSchema = operationsBaseSchema.omit({ page: true, sort: true }).extend({
  dateTime: z
    .custom<DateTime>((arg) => arg instanceof DateTime)
    .nullable()
    .array()
    .length(2)
    .optional()
    .superRefine((dateTime, context) => {
      // The input cannot have one null value and one valid value - both of them needs to be null or luxon.DateTime
      if (dateTime && filter(dateTime, (luxonDateTimeItem) => isNil(luxonDateTimeItem)).length === 1) {
        context.addIssue({
          code: z.ZodIssueCode.custom,
          fatal: true,
          message: i18n.t('operationsPage.validations.required')
        });
      }

      if (dateTime && every(dateTime, (luxonDateTimeItem) => luxonDateTimeItem instanceof DateTime)) {
        const startedAt = dateTime[0]!;
        const endedAt = dateTime[1]!;

        handleDateTimeValidationError(startedAt, endedAt, context);
      }
    })
});

export type OperationsOverviewSearchParameters = z.infer<typeof operationsOverviewStatesSchema>;
export type OperationsFilters = z.infer<typeof operationsFilterSchema>;
