import { capitalize, lowerCase } from 'lodash';

import i18n from '../../i18n';
import { ObjectUploadStatus } from '../../types';

export const mapObjectUploadStatusDescription = (uploadStatus: ObjectUploadStatus): string => {
  switch (uploadStatus) {
    case ObjectUploadStatus.Draft:
      return i18n.t('objectUploadStatus.draft');
    case ObjectUploadStatus.UploadFailed:
      return i18n.t('objectUploadStatus.uploadFailed');
    case ObjectUploadStatus.UploadFailedNotFound:
      return i18n.t('objectUploadStatus.uploadFailedNotFound');
    case ObjectUploadStatus.UploadRequestFailed:
      return i18n.t('objectUploadStatus.uploadRequestFailed');
    case ObjectUploadStatus.UploadRequestQueued:
      return i18n.t('objectUploadStatus.uploadRequestQueued');
    case ObjectUploadStatus.UploadRequested:
      return i18n.t('objectUploadStatus.uploadRequested');
    default:
      return i18n.t('objectUploadStatus.unknown');
  }
};

export const mapObjectUploadStatus = (uploadStatus: ObjectUploadStatus): string => {
  return capitalize(lowerCase(uploadStatus));
};
